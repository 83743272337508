
import { defineComponent } from 'vue';
import { getAvailable, available } from '@/api/commentary';
import { isLoaded } from '@/interfaces/RemoteData';

export default defineComponent({
  setup() {
    getAvailable();
    return {
      available,
      isLoaded,
    };
  },
});

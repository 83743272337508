import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-2 px-2 align-top text-right whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", {
      class: _normalizeClass(["pt-2 w-1/2 arabic align-top", _ctx.line.insert ? 'pr-6' : ''])
    }, _toDisplayString(_ctx.line.ar), 3),
    _createElementVNode("td", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.part) + " " + _toDisplayString(_ctx.decade) + " ", 1),
      _createElementVNode("sup", null, _toDisplayString(_ctx.verse), 1)
    ]),
    _createElementVNode("td", {
      class: _normalizeClass(["pt-2 w-1/2 align-top", _ctx.line.insert ? 'pl-6' : ''])
    }, _toDisplayString(_ctx.line.de), 3)
  ]))
}

import { defineComponent, onMounted, onUpdated, PropType, watch } from 'vue';
import { connectQuranRefsToPopper,
  getData,
  hide,
  reallyHide,
  show } from '@/components/commentary/popper';
import { createLinks } from '@/components/commentary/linker';
import { Commentary } from '@/interfaces/Commentary';
import CommentarySection from '@/components/commentary/CommentarySection.vue';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import CommentaryLine from '@/components/commentary/CommentaryLine.vue';
import { useRoute, useRouter } from 'vue-router';
import routeParamToString from '@/router/param_as_string';
import scrollToHash from '@/components/global/scrollToHash';
import BackToTop from '../intertext/BackToTop.vue';

export default defineComponent({
  components: {
    BackToTop,
    CommentarySection,
    ArticleContainer,
    CommentaryLine,
  },
  props: {
    commentary: Object as PropType<Commentary>,
  },
  setup() {
    /** Note: this setup logic is largely a duplicate
    of the CommentaryIntroduction logic. Maybe you can combine them?
    * */
    const router = useRouter();
    const route = useRoute();

    function makeLinksAndPoppers() {
      createLinks(router, route);

      // Hook up Quran references to popper.
      connectQuranRefsToPopper(routeParamToString(route.params.lang));
    }

    onMounted(() => {
      scrollToHash(route);

      makeLinksAndPoppers();
    });

    onUpdated(() => {
      makeLinksAndPoppers();
    });
  },
});

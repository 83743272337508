import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", {
      class: "font-bold",
      id: _ctx.commentaryVersesId(_ctx.sectionId, _ctx.commentary)
    }, _toDisplayString(_ctx.commentaryVersesTitle(_ctx.commentary)), 9, _hoisted_1),
    _createElementVNode("div", {
      class: "pb-6",
      innerHTML: _ctx.commentary.content
    }, null, 8, _hoisted_2)
  ], 64))
}

import { defineComponent, PropType } from 'vue';
// Stelle sicher, dass der Import von MoreInfo vor ArticleContainer erfolgt
import MoreInfo from '@/components/global/MoreInfo.vue';
import { CommentarySection } from '@/interfaces/CommentarySection';
import ArticleContainer from '../global/ArticleContainer.vue';
import CommentaryVerses from './CommentaryVerses.vue';
import BackToTop from '../intertext/BackToTop.vue';

export default defineComponent({
  components: {
    MoreInfo,
    ArticleContainer,
    CommentaryVerses,
    BackToTop,
  },
  props: {
    section: Object as PropType<CommentarySection>,
  },
  data() {
    return {
      showInfo: false,
    };
  },
});


import { defineComponent, PropType } from 'vue';
import { CommentaryVerses,
  commentaryVersesId,
  commentaryVersesTitle } from '@/interfaces/CommentaryVerses';

export default defineComponent({
  props: {
    commentary: Object as PropType<CommentaryVerses>,
    sectionId: String,
  },
  setup() {
    return {
      commentaryVersesTitle,
      commentaryVersesId,
    };
  },
});

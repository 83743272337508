import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-wrap max-w-sm mx-auto py-6 showLinks"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('commentary.available_commentary')), 1),
    (_ctx.isLoaded(_ctx.available))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available.payload, (sura) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              class: "w-12 text-left p-2",
              key: sura,
              to: {
        name: 'VerseCommentary',
        params: {
          sura: sura,
          verse: 1,
        },
      }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(sura), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ArrowNavigation = _resolveComponent("ArrowNavigation")!

  return (_openBlock(), _createBlock(_component_ArrowNavigation, {
    class: "py-3",
    textLeft: _ctx.$t('navigation.next_sura'),
    textRight: _ctx.$t('navigation.previous_sura'),
    leftLink: _ctx.nextSura,
    rightLink: _ctx.previousSura
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, { to: { name: 'CommentaryOverview' } }, {
        default: _withCtx(() => [
          _createVNode(_component_PrimaryButton, { class: "w-72 mb-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('commentary.to_overview')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["textLeft", "textRight", "leftLink", "rightLink"]))
}